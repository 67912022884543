<template>
    <component :is="componentObject"></component>
</template>

<script>
    export default {
        props: {
            component: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
            extension: {
                type: String,
                required: false,
                default: 'core'
            },
        },
        computed: {
            componentObject () {
                return () => import(`../../../${this.extension}/components/${this.type}/${this.component}.vue`)
            }
        }
    }
</script>